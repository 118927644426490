.news-carousel-container {
  width: 100%;
  overflow: hidden;
  height: 80%;
  position: relative;
}

.news-item {
  position: relative;
  transition: transform 1s cubic-bezier(0.86, 0, 0.07, 1);
  transform-style: preserve-3d;
}

.slick-slide {
  perspective: 1000px; /* Enables the 3D effect */
}

.slick-active .news-item {
  transform: rotateY(0deg);
}

.slick-prev, .slick-next {
  font-size: 24px;
  z-index: 10;
}

.news-content-container {
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
}

.news-image-container {
  width: 100%;
  height: 85vh; /* Fixed height for all images */
  overflow: hidden;
  position: relative;
}

.news-image {
  width: 100%;
  height: 100%;
  object-fit: fill;
  transition: transform 0.6s ease-in-out;
}

.news-item:hover .news-image {
  transform: scale(1.05); /* Slight zoom effect */
}

.news-overlay {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 20px;
  text-align: center;
  backdrop-filter: blur(8px); /* Adds a glass effect */
}

.news-title {
  font-size: 24px;
  font-weight: bold;
}

/* Custom Navigation Arrows */
.custom-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.3);
  border: none;
  padding: 12px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 10;
  color: white;
  font-size: 24px;
  transition: all 0.3s ease-in-out;
}

.custom-arrow:hover {
  background: rgba(255, 255, 255, 0.6);
  transform: translateY(-50%) scale(1.2);
}

.prev-arrow {
  left: 15px;
}

.next-arrow {
  right: 15px;
}
